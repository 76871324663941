import React from 'react';
import {useState, useEffect} from 'react';
import './Product.css';
import * as _ from 'underscore'
import QuickSpecs from './QuickSpecs'
import * as $ from 'jquery'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class Product extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {};
    // Take the first available spec category and expand it by default
    _.any(this.props.product.specs, (spec) => {
      if (spec.title != 'Quick Specs') {
        this.state['specs-' + spec.title] = true;
        return true;
      }
    });
    this.state.variant = this.props.product.variants[0];
    this.state.variantChangedAt = 0; // this is for keeping the color after the user manually clicks
  }

  componentDidMount() {
    this.interval = setInterval(()=> {
      if (Date.now() - this.state.variantChangedAt > 60000) { // After a minute we can go back to cycling
        const index = _.indexOf(this.props.product.variants, this.state.variant);
        this.setState({variant: this.props.product.variants[(index + 1) % this.props.product.variants.length]});
      }
    }, 15000);
  }
  componentWillUnmount () {
    clearInterval(this.interval);
    clearTimeout(this.accessoryTimeout);
  }

  renderFeaturedAccessory() {
    const {accessory} = this.state;
    return <div id="selected-accessory">

      <button style={{
        top: '.7vw',
        padding: '1vw 2vw',
        right: '1vw',
        position: 'absolute',
        fontSize: '3vw',
        background: 'white',
        border: 0,
      }} onClick={(e) =>
        this.setState({accessory: null})
      }>
        &times;
      </button>

      <div style={{
        borderBottom: '2px solid #cccccc',
        height: '2.05vw',
        marginLeft: '1.2vw',
        marginRight: '2.5vw',
        marginBottom: '1.5vw',
      }}>
        <div style={{
          background: 'white',
          display: 'inline-block',
          margin: 'auto',
          fontSize: '3vw',
          fontWeight: 'bold',
          padding: '0 1vw',
        }}>
          {this.props.product.name} Featured Accessory
        </div>
      </div>

      <div style={{
        padding: '5vw'
      }}>
        <div>
          <div  style={{
            backgroundImage: `url('/data/images/${accessory.images.main}')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
            backgroundColor: '#fafafa',
            width: '50%',
            height: '30vw',
            float: 'left',
          }} />
          <div style={{
            width: '50%',
            float: 'left',
            padding: '0 3vw',
            textAlign: 'left',
          }}>
            <div style={{
              fontSize: '5vw',
            }}>
              {accessory.name}
            </div>
            <div style={{
              marginTop: '2vw',
              fontSize: '2.5vw',
            }}>
              Suggested Retail Price of {USDollar.format(accessory.variants[0].msrp)}
            </div>
          </div>
          <div style={{clear: 'both'}} />
        </div>
        <div style={{
          padding: '0 .5vw',
          textAlign: 'left',
          fontSize: '2.5vw',
        }} dangerouslySetInnerHTML={{__html: accessory.variants[0].longDescription}} />
      </div>

    </div>
  }

  render() {
    const {product} = this.props;
    return <div>
      <div id="ocean-backdrop">
        <div id="ocean-backdrop-inner" className={(product.type == 'PWC' ? 'pwc' : '')} />
        <div id="left-info">
          <div id="product-name">
            {product.name}
          </div>
          <div id="color-swatches">
            <div className='available-in-text' style={{
              paddingRight: '2vw',
              paddingBottom: '.3vw',
            }}>
              Available in:
            </div>
            {product.variants.map((variant) => {
              return <img
                className="color-swatch"
                src={`/data/images/${variant.images.swatch}`}
                alt="none"
                onClick={(e) =>
                  this.setState({variant, variantChangedAt: Date.now()})
                }
              />
            })}
          </div>
        </div>
        {product.variants.map( variant => 
          <div className="boat-side" style={{
            backgroundImage: `url('/data/images/${variant.images.alternate}')`,
            zIndex: variant == this.state.variant ? 10 : 5,
            opacity: variant == this.state.variant ? 1 : 0,
          }} />
        )}
      </div>
      <QuickSpecs product={product} />
      <div id="product-nav">
        {_.map({
          '#specifications-section': 'Specifications',
          '#accessories-section': 'Accessories',
        }, (title, selector) =>
          <button onClick={() => {
            $(window).scrollTop(
              $(selector).offset().top - $(window).width() * .50
            )
            this.setState({accessory: null});
          }}>
            {title}
          </button>
        )}
      </div>
      <div id="specifications-section" style={{
        margin: '2vw 5vw',
        paddingTop: '2.5vw',
      }}>
        <div style={{
          borderBottom: '2px solid #d5d5d5',
          height: '2.3vw',
          marginLeft: '1.2vw',
          marginRight: '2.5vw',
          marginBottom: '3.5vw',
        }}>
          <div style={{
            background: '#ffffff',
            display: 'inline-block',
            margin: 'auto',
            fontSize: '3vw',
            fontWeight: 'bold',
            padding: '0 1vw',
          }}>
            Specifications
          </div>
        </div>
        <div style={{borderBottom: '.33vw solid #eee'}}>
        {_.compact(_.map(product.specs, (specs) => {
          if (specs.name == 'Quick Specs') return;
          const key = 'specs-' + specs.name;
          return <div>
            <div className='specs-header' onClick={()=>
              this.setState({[key]: !this.state[key]})
            }>
              <div style={{
                float: 'right'
              }}>{this.state[key] ? '-' : '+'}</div>
              {specs.name}
            </div>
            {this.state[key] &&
              <div>
                {_.compact(specs.tabDataItems.map((spec) => {
                  console.log(spec);
                  if (spec.value) {
                    return <div className='specs-row'>
                      <div className='specs-column'>
                        {spec.name}
                      </div>
                      <div className='specs-column'>
                        {spec.value === "true" || spec.value === true ? <div className='fa fa-check-circle' /> : spec.value}
                      </div>
                    </div>
                  }
                }))}
              </div>
            }
          </div>;
        }))}
        </div>
      </div>
      <div id="accessories-section" style={{
        margin: '4vw 0px 0',
        background: '#fafafa',
        paddingLeft: '1.3vw',
        paddingTop: '2.5vw',
        paddingBottom: '2.5vw',
      }}>
        <div style={{
          borderBottom: '2px solid #d5d5d5',
          height: '2.3vw',
          marginLeft: '1.2vw',
          marginRight: '2.5vw',
          marginBottom: '3.5vw',
        }}>
          <div style={{
            background: '#fafafa',
            display: 'inline-block',
            margin: 'auto',
            fontSize: '3vw',
            fontWeight: 'bold',
            padding: '0 1vw',
          }}>
            Accessories
          </div>
        </div>
        {this.state.accessory && this.renderFeaturedAccessory()}
        {_.compact(_.map(product.accessories, (accessory) => {
          if (accessory.variants[0].isFeatured)
          return <div className='accessory' onClick={(e) => {
            //$('html,body').animate({scrollTop: $(e.target).offset().top});
            this.setState({accessory});
            clearTimeout(this.accessoryTimeout);
            this.accessoryTimeout = setTimeout(()=> this.setState({accessory: null}), 5 * 60 * 1000);
          }}>
            <div className='accessory-image' style={{
              backgroundImage: `url('/data/images/${accessory.images.main}')`
            }} />
            <div className='accessory-text'>
              {accessory.name}
            </div>
          </div>
        }))}
        <div style={{clear: 'both'}} />
      </div>
    </div>;
  }
}

export default Product;
