import React from 'react';
import './App.css';
import './css/adobe-fonts.css';
import './css/fontawesome-free-5.7.2-web/css/all.css';
import * as json from './yamaha-watercraft-touch-data-new.json';
import Product from './Product';
import * as $ from 'jquery'
import * as _ from 'underscore'

class App extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {windowTop: true};
    if (localStorage.getItem('selectedProduct')) {
      this.state.selectedProduct = _.findWhere(json.default.Products, {code: localStorage.getItem('selectedProduct')});
    }
  }
  componentDidMount() {
    $(window).on('scroll', () => {
      if ($(window).scrollTop() === 0) {
        this.setState({windowTop: true});
      } else {
        this.setState({windowTop: false});
      }
    });
    //if (!navigator.serviceWorker.controller) {
    navigator.serviceWorker.addEventListener("message", (event) => {
      const { urlsCached, totalURLs } = event.data;
      // Display a message about how many URLs have been cached.
      if (totalURLs) {
        this.setState({totalURLs, urlsCached});
      }
      if (totalURLs && urlsCached && totalURLs == urlsCached ) {
      }
    });
    //}
    setTimeout(() => {
      console.log(window.MOTOTV_VERSION, +localStorage.MOTOTV_VERSION);
      if (+window.MOTOTV_VERSION && window.MOTOTV_VERSION !== +localStorage.MOTOTV_VERSION) {
        navigator.serviceWorker.getRegistrations().then(async function(registrations) {
          for(let registration of registrations) {
            await registration.unregister()
          }
          console.log('reset');
          localStorage.setItem('MOTOTV_VERSION', window.MOTOTV_VERSION);
          window.location.reload();
        }).catch(function(err) {
          console.log('Service Worker registration failed: ', err);
        });
      }
    }, 2000);
  }
  render() {
    if (this.state.totalURLs && this.state.urlsCached < this.state.totalURLs) {
      return <div style={{
        fontSize: '4vw',
        padding: '10vw',
        textAlign: 'center',
        fontFamily: 'Proxima Nova',
      }}>
        <div id="centered-logo"></div>
        <br /><br />
        Loading ... {(this.state.urlsCached / this.state.totalURLs * 100).toFixed(2)}%.
      </div>;
    }

    const sortedProducts = _.sortBy(json.default.Products, product => product.name);
    return (
      <div className={"App " + (this.state.windowTop ? '' : 'scrolled')}>
        <div id="password-prompter" onClick={(e) =>
            this.setState({passwordPrompt: true})
        } />
        {this.state.passwordPrompt && <div id="password-prompt">
          <div id="centered-logo">
          </div>
          <br /> <br />
          Enter Password
          <br /> <br />
          <input autoFocus={true} type='number' onChange={(e) => {
            if (e.target.value === '7592') {
              this.setState({
                selectedProduct: null,
                passwordPrompt: null,
              });
            }
          }} />
          <br /><br />
          <button onClick={() => this.setState({passwordPrompt: null})}>Cancel</button>
        </div>}
        <div id="cover" />
        <header className="App-header">
          <div id="top-logo">
          </div>
          <div id="choose">
            <div id="choose-text-red">
              Explore
            </div>
            <div style={{paddingLeft: 7}}>Your</div>
            {this.state.selectedProduct && this.state.selectedProduct.type === 'PWC' ? 'WaveRunner' : 'Boat'}
          </div>
        </header>
        {this.state.selectedProduct && <Product product={this.state.selectedProduct} windowTop={this.state.windowTop} />}
        <div>
          {!this.state.selectedProduct && <div id="select-product">
            <div style={{
              float: 'left',
              width: '50%',
            }}>
              <h2> PWC </h2>
              {_.compact(_.map(sortedProducts, (product) => {
                if (product.type ==='PWC') {
                  return <div className="select-product-row" onClick={() => {
                    localStorage.setItem('selectedProduct', product.code);
                    this.setState({selectedProduct: product})
                  }}>
                    {product.name}
                  </div>
                }
              }))}
            </div>
            <div style={{
              float: 'left',
              width: '50%',
            }}>
              <h2> Boats </h2>
              {_.compact(_.map(sortedProducts, (product) => {
                if (product.type ==='Boat') {
                  return <div className="select-product-row" onClick={() => {
                    localStorage.setItem('selectedProduct', product.code);
                    this.setState({selectedProduct: product})
                  }}>
                    {product.name}
                  </div>
                }
              }))}
            </div>
          </div>}
          <div style={{clear: 'both'}} />
        </div>
      </div>
    );
  }
}

export default App;
