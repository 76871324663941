import * as _ from 'underscore'
let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function QuickSpecs(props) {
  const {product} = props
  const quickSpecs = _.findWhere(product.specs, {name: 'Quick Specs'});
  return <div id="quick-specs" style={{
    paddingLeft: 7
  }}>
    <div className='quick-spec-column'>
      <div>
      <div className='quick-spec-column-label'>
        Starting At
      </div>
      {USDollar.format(product.startingAt)}
      </div>
    </div>
    {quickSpecs.tabDataItems.map((content) =>
      <div className='quick-spec-column'>
        <div>
          <div className='quick-spec-column-label'>
            {content.name}
          </div>
          {content.value}
        </div>
      </div>
    )}
    <div className='quick-spec-column'>
      <div>
        <div className='quick-spec-column-label'>
          Color
        </div>
        {product.variants[0].name}
      </div>
    </div>
  </div>
}

export default QuickSpecs;
